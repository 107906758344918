import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import "../components/css/page/linkbutton.css"

class StickerPage extends React.Component {

  state = { 
    tagsOn: [],
    tagsOpen: false 
  }

  openStickerPage () {
    window.open('https://smartstore.naver.com/honeykers', '_blank')
  }

  render () {
    return (
      <Layout page="sticker">
        <SEO title="홈"/>
        <div id="smartstore-link-container">
          <div
            role="button"
            className="link" 
            onClick={ this.openStickerPage }
            onKeyUp={ this.openStickerPage }
            tabIndex={0}
          >스토어 바로가기</div>
        </div>
      </Layout>
    )
  }

  componentDidMount () {
    window.location.replace('https://smartstore.naver.com/honeykers')
  }
}


// const StickersPage = () => {
// }

export default StickerPage
